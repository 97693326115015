import { Injectable } from '@angular/core';

import { createSelector } from 'reselect';

import { State } from '../reducers';
import * as fromUserLogs from '../reducers/user-logs.reducer';

@Injectable()
export class UserLogsSelectors {
    /**
     * Get state.userActions
     */
    getUserLogsState() {
        return ($state: State): any => $state.userLogs;
    }
    /**
     * Get list from state.userActions
     */
    getList() {
        return createSelector(
            this.getUserLogsState(),
            fromUserLogs.getList,
        );
    }

    /**
     * Get list.data from state.userActions
     */
    getListData() {
        return createSelector(
            this.getUserLogsState(),
            fromUserLogs.getListData,
        );
    }

    /**
     * Get list.errors from state.userActions
     */
    getListErrors() {
        return createSelector(
            this.getUserLogsState(),
            fromUserLogs.getListErrors,
        );
    }

    /**
     * Get list.isFetching from state.userActions
     */
    getListIsFetching() {
        return createSelector(
            this.getUserLogsState(),
            fromUserLogs.getListIsFetching,
        );
    }
}
