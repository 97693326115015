import { type } from '@app/utils';
import { Action } from '@ngrx/store';

const ACTION_PREFIX = '[User Logs]';

export const ActionTypes = {
    GET_USER_LOGS_REQUEST: type(`${ACTION_PREFIX} Get User Logs Request`),
    GET_USER_LOGS_SUCCESS: type(`${ACTION_PREFIX} Get User Logs Success`),
    GET_USER_LOGS_ERROR: type(`${ACTION_PREFIX} Get User Logs Error`),
    CLEAR_LIST: type(`${ACTION_PREFIX} Clear List`),
};

/**
 * Create 'Get User Logs Request' Action
 * @param payload Contains object with list params (offset and limit)
 */
export class GetUserLogsRequestAction implements Action {
    type = ActionTypes.GET_USER_LOGS_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get User Logs Succes' Action
 * @param payload Contains object with user logs list and params
 */
export class GetUserLogsSuccessAction implements Action {
    type = ActionTypes.GET_USER_LOGS_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get User Logs Error' Action
 * @param payload Contains object with errors
 */
export class GetUserLogsErrorAction implements Action {
    type = ActionTypes.GET_USER_LOGS_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear List' Action
 */
export class ClearListAction implements Action {
    type = ActionTypes.CLEAR_LIST;

    constructor(public payload?: any) {}
}

export type Actions = GetUserLogsRequestAction | GetUserLogsSuccessAction | GetUserLogsErrorAction | ClearListAction;
