import * as userLogsActions from '../actions/user-logs.actions';
import { UserLogsList } from '../models';

export interface State {
    list: UserLogsList;
}

const initialState: State = {
    list: {
        data: {
            results: [],
            previous: null,
            count: null,
            next: null,
        },
        errors: {},
        isFetching: true,
    },
};

export function reducer(state = initialState, action: userLogsActions.Actions) {
    switch (action.type) {
        case userLogsActions.ActionTypes.GET_USER_LOGS_REQUEST: {
            return {
                ...state,
                list: {
                    ...state.list,
                    errors: {},
                    isFetching: true,
                },
            };
        }

        case userLogsActions.ActionTypes.GET_USER_LOGS_SUCCESS: {
            return {
                ...state,
                list: {
                    ...state.list,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case userLogsActions.ActionTypes.GET_USER_LOGS_ERROR: {
            return {
                ...state,
                list: {
                    ...state.list,
                    errors: action.payload,
                    isFetching: false,
                },
            };
        }

        case userLogsActions.ActionTypes.CLEAR_LIST: {
            return {
                ...state,
                list: {
                    ...initialState.list,
                },
            };
        }

        default:
            return state;
    }
}

export const getList = (state: State) => state.list;
export const getListData = (state: State) => state.list.data;
export const getListErrors = (state: State) => state.list.errors;
export const getListIsFetching = (state: State) => state.list.isFetching;
